/** @jsx jsx */
import { BaseStyles, jsx } from "theme-ui"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import Metadata from "../components/Metadata"

// styles
const mainContainer = {
  backgroundColor: "white",
  justifyContent: "center",
  display: "flex",
  width: "100%",
  height: "600px",
  paddingTop: "50px",
}
const columnContainer = {
  width: "820px",
}

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <Metadata />
      <BaseStyles>
        <div sx={mainContainer}>
          <div sx={columnContainer}>
            <div sx={{ fontSize: ["32px", "32px", "60px"], fontWeight: 100 }}>
              404 Not Found
            </div>
            <div sx={{ marginTop: "50px" }}>
              The page you tried to access was not found. Please click{" "}
              <Link to="/">here</Link> to return to the home page.
            </div>
          </div>
        </div>
      </BaseStyles>
    </Layout>
  )
}

export default NotFoundPage
